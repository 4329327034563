<template>
  <div style="height: 100vh; width: 100vw;">
    <div
      class="d-flex justify-content-center align-items-center full-height"
    >
      <div
        class="col-5 align-self-center"
      >
        <b-card>
          <hwa-input
            v-model="first_name"
            label="First Name"
            placeholder="Enter first name"
          />
          <hwa-input
            v-model="last_name"
            label="Last Name"
            placeholder="Enter last name"
          />
          <hwa-input
            v-model="email"
            label="Email"
            placeholder="Enter email"
            type="email"
          />
          <hwa-input
            v-model="phone"
            label="Phone"
            placeholder="Enter phone"
          />
          <hwa-input
            v-model="password"
            label="Password"
            placeholder="Enter password"
            type="password"
            rules="required"
          />
          <hwa-input
            v-model="password_confirmation"
            label="Confirm Password"
            placeholder="Confirm password"
            type="password"
            rules="required"
          />
          <div class="mt-2" />
          <div class="d-flex">
              <hwa-button
                      icon="ArrowLeftCircleIcon"
                      label="PREVIOUS"
                      @onClick="$router.back()"
              />
              <div class="ml-1" />
            <hwa-button
              :disabled="!email"
              label="Complete"
              :loading="nextLoading"
              @onClick="complete()"
            />
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default {
  name: 'StartPurchase2',
  components: {
    HwaButton,
    HwaInput,
    BCard,
    GridLoader,
  },
  data() {
    return {
      nextLoading: false,
      email: null,
      first_name: null,
      last_name: null,
      phone: null,
      password_confirmation: null,
      password: null,
    }
  },
  methods: {
    complete() {
      const id = localStorage.getItem('anonymous')
      this.nextLoading = true
      this.$http.post(`api/complete/${this.$route.params.type}/${id}`, {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone: this.phone,
        password: this.password,
        password_confirmation: this.password_confirmation,
      })
        .then(res => {
          this.nextLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: 'You are all set.',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'login' })
        })
        .catch(err => {
          this.nextLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed',
              icon: 'CheckCircleIcon',
              text: 'Something went wrong, try again.',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>

</style>
